import React from 'react';

function ProjectPage()  {
        return (
            <div className="App-body" id="projects">
                This is the project page for dtwilliams10.com
            </div>
        );
    }

export default ProjectPage;