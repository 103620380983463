import React from 'react';

function Contact()  {
        return (
            <div className="App-body" id="contact">
                This is the contact page for dtwilliams10.com
            </div>
        );
    }

export default Contact;