import React from 'react';

function Footer()  {
        return (
            <div>
                Tyler Williams - Software Developer
            </div>
        );
    }

export default Footer;