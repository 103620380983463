import React from 'react';

function AboutPage()  {
        return (
            <div className="App-body" id="about">
                This is the about page for dtwilliams10.com
            </div>
        );
    }

export default AboutPage;